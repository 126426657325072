import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import HouseIcon from '@mui/icons-material/House';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RedeemIcon from '@mui/icons-material/Redeem';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PostAddIcon from '@mui/icons-material/PostAdd';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import CampaignIcon from '@mui/icons-material/Campaign';
import OfficeIcon from '@mui/icons-material/LocalPostOffice';
import AssessmentIcon from '@mui/icons-material/Assessment';

import type { MenuItems } from './types';

export const COMPANY_ITEMS: MenuItems[] = [
  {
    title: 'Dashboard',
    url: '/',
    icon: DashboardIcon,
  },
  {
    title: 'CRM',
    icon: HandshakeIcon,
    child: [
      {
        title: 'Customer',
        url: '/customer',
        icon: AccountBoxIcon,
      },
      {
        title: 'Activity',
        url: '/crm/activity',
        icon: PlayArrowIcon,
      },
      {
        title: 'Channel',
        url: '/crm/channel',
        icon: SubscriptionsIcon,
      },
      {
        title: 'Channel Event',
        url: '/crm/channel-event',
        icon: CalendarMonthIcon,
      },
      {
        title: 'Ads Project',
        url: '/crm/ads-project',
        icon: CalendarMonthIcon,
      },
      {
        title: 'Whatsapp Blast',
        url: '/tools/whatsapp-blast',
        icon: WhatsappIcon,
      },
    ],
  },
  {
    title: 'Sales',
    icon: MonetizationOnIcon,
    child: [
      {
        title: 'Transaction',
        url: '/sales/transaction',
        icon: ReceiptLongIcon,
      },
      {
        title: 'Commission',
        url: '/sales/commission',
        icon: RedeemIcon,
      },
      {
        title: 'Cash Reward',
        url: '/sales/cash-reward',
        icon: RedeemIcon,
      },
      {
        title: 'Non Cash Reward',
        url: '/sales/non-cash-reward',
        icon: RedeemIcon,
      },
    ],
  },
  {
    title: 'Property',
    icon: HouseIcon,
    child: [
      {
        title: 'Project',
        url: '/project',
        icon: AccountTreeIcon,
      },
      {
        title: 'Product',
        url: '/product',
        icon: HouseIcon,
      },
      {
        title: 'Listing',
        url: '/listing',
        icon: HomeWorkIcon,
      },
    ],
  },
  {
    title: 'User',
    url: '/user',
    icon: PeopleIcon,
    child: [
      {
        title: 'Internal User',
        url: '/user/internal-user',
        icon: PeopleIcon,
      },
      {
        title: 'Standard User',
        url: '/user/standard-user',
        icon: PeopleIcon,
      },
      {
        title: 'Team',
        url: '/user/team',
        icon: PeopleIcon,
      },
      {
        title: 'Team Member',
        url: '/user/team-member',
        icon: PeopleIcon,
      },
    ],
  },
  {
    title: 'NUP',
    icon: SummarizeIcon,
    child: [
      {
        title: 'Project NUP',
        url: '/nup/project-nup',
        icon: PostAddIcon,
      },
      {
        title: 'Transaction NUP',
        url: '/nup/transaction-nup',
        icon: ReceiptLongIcon,
      },
    ],
  },
  {
    title: 'Launching',
    icon: OfficeIcon,
    child: [
      {
        title: 'Counter Setup',
        url: '/nup/counter-setup',
        icon: PostAddIcon,
      },
      {
        title: 'Counter Action',
        url: '/nup/counter-action',
        icon: PostAddIcon,
      },
    ],
  },
  {
    title: 'Report',
    icon: AssessmentIcon,
    child: [
      {
        title: 'Sales Report',
        url: '/report/sales',
        icon: PostAddIcon,
      },
      {
        title: 'CRM Export',
        url: '/report/crm-data',
        icon: PostAddIcon,
      },
    ],
  },
  {
    title: 'Sales Material',
    icon: CampaignIcon,
    child: [
      {
        title: 'Sales Material Group',
        url: '/sales-material/group',
        icon: CampaignIcon,
      },
      {
        title: 'Sales Material',
        url: '/sales-material/master',
        icon: CampaignIcon,
      },
      {
        title: 'Sales Material Detail',
        url: '/sales-material/detail',
        icon: CampaignIcon,
      },
    ],
  },
  {
    title: 'News Event',
    url: '/news-event',
    icon: EventIcon,
  },
];
